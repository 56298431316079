import * as React from 'react';
import { RecipientHeader } from './RecipientHeader';
import Notification from '../Common/Notification/NotificationContainer'
import { Toaster } from '../../components/Common/Notification/MarsNotifier';
import { RouteComponentProps } from 'react-router';
import { ILocalStore } from '../../core/utilities/LocalStore';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import { match } from 'react-router';
import { History } from 'history';
import { IHeaderInfoViewModel } from '../../core/domain/viewModels/IHeaderInfoViewModel';
import * as AccountStore from '../../store/Common/AccountStore';
import { CCRecipientSessionTimeout } from '../Account/CCRecipientSessionTimeout';
import { OverlayLoader } from '../Common/Loader/OverlayLoader';
import { IProfileData } from './DefaultLayout';
import { getClientBasicInfo, injectPendoScript } from '../Helper/HelperFunction';

export type CcRecipientDefaultLayoutProps =
    {
        recipientHeaderInfo: IHeaderInfoViewModel;
        loading: boolean;
        match: match;
        history: History;
        logOutCC:(clientGuid:string,callback: (url: string) => void) => void;
    }
    & typeof AccountStore.actionCreators
    & RouteComponentProps<{}>;


interface ICcRecipientDefaultLayoutState {
    idleSessionTimeoutInSeconds: number;
    profileData: IProfileData | undefined;
	isPendoInjected: boolean;
};


const localStore = container.get<ILocalStore>(TYPES.ILocalStore);



export class CcRecipientDefaultLayout extends React.Component<CcRecipientDefaultLayoutProps, ICcRecipientDefaultLayoutState> {

    constructor(props: CcRecipientDefaultLayoutProps, states: any) {
        super(props, states);

        this.state = {
            idleSessionTimeoutInSeconds: 0,
            profileData: undefined,
            isPendoInjected: false
        }
    }

    componentDidMount() {
        let param: any = this.props.match.params;
        this.props.requestRecipientHeaderInfo(param.clientId);
        this.props.getSessionTimeOutSeconds(param.clientId, this.setSessionTimout);
        getClientBasicInfo(param.clientId, this.updateProfileData);
    }

    componentDidUpdate(prevProps: Readonly<CcRecipientDefaultLayoutProps>, prevState: Readonly<ICcRecipientDefaultLayoutState>): void {
		if (!this.state.isPendoInjected && this.state.profileData) {
			const { companyId, companyName, clientGuid, clientName, emailAddress } = this.state.profileData;
			injectPendoScript(
				companyId,
				companyName,
				clientGuid,
				clientName,
				emailAddress,
				this.state.isPendoInjected,
				this.setIsPendoInjected
			);
		}
	}

    setIsPendoInjected = (isPendoInjected: boolean) => {
		this.setState({ isPendoInjected });
	}

	updateProfileData = (data: IProfileData) => {
		this.setState({ profileData: data });
	}

    setSessionTimout = (sessionTimeoutValue: number) =>{
		this.setState({ idleSessionTimeoutInSeconds: sessionTimeoutValue })		
	}

    public render() {
        return (
            <div>
                <RecipientHeader
                    headerInfo={this.props.recipientHeaderInfo}
                    match={this.props.match}
                    history={this.props.history}
                    logout={this.props.logout}
                />
                <div id="content-wrapper" className="content-wrapper" >
                    <Notification />
                    {this.props.children}
                </div>
                {this.state.idleSessionTimeoutInSeconds > 0 && <CCRecipientSessionTimeout
                    match={this.props.match}
                    idleSessionTimeoutInSeconds={this.state.idleSessionTimeoutInSeconds}
                    refreshToken={this.props.refreshToken}
                    logOutCC={this.props.logOutCC}
                    history={this.props.history}
                />}
                <OverlayLoader />
                <Toaster />

            </div>)
    }
}