import * as React from 'react';
import { History } from 'history';
import IdleTimer from 'react-idle-timer';
import { match, RouteComponentProps } from 'react-router';
import { Toaster } from '../../components/Common/Notification/MarsNotifier';
import * as TaxDocument from '../../core/domain/models/ITaxReturn';
import { ILocalStore } from '../../core/utilities/LocalStore';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import * as AccountStore from '../../store/Common/AccountStore';
import * as EsignStore from '../../store/Common/EsignStore';
import * as HelperStore from '../../store/Common/HelperStore';
import * as TaxDocumentStore from '../../store/Common/TaxDocumentStore';
import { SessionTimeout } from '../Account/SessionTimeout';
import { OverlayLoader } from '../Common/Loader/OverlayLoader';
import Notification from '../Common/Notification/NotificationContainer';
import * as DownloadableDocument from 'src/core/domain/viewModels/IDownloadableDocumentsViewModel';
import { Header } from './Header';
import { PathConstants } from '../Common/Constants';
import { ISignerModel } from 'src/core/domain/models/ISignerModel';
import { IHeaderInfoViewModel } from 'src/core/domain/viewModels/IHeaderInfoViewModel';
import { Role } from 'src/core/common/Enums';
import { logger } from '../../routes';
import { LogEventConstants } from '../Helper/Constants';
import { ICompany } from 'src/core/domain/models/company/Company';
import { IClientProcessViewModel } from 'src/core/domain/viewModels/IClientProcessViewModel';
import { getClientBasicInfo, injectPendoScript } from '../Helper/HelperFunction';

declare global {
    interface Window {
        Variables: any;
        pendo: any;
    }
}
export type DefaultLayoutProps =
    {
        headerInfo: IHeaderInfoViewModel;
        match: match;
        history: History;
        loading: boolean;
        taxReturn: TaxDocument.ITaxReturn;
        requestMyDownload: (clientId: string) => any;
        deleteMyDownloads: (downloadId: string, clientId: string) => void;
        clearAllMyDownloads: (clientId: string) => void;
        signerData: ISignerModel[];
        downloadList: DownloadableDocument.IDownloadableDocumentsViewModel;
        iceServers: RTCIceServer[];
        getIceServers: (clientId: string) => void;
        layoutStyle: any;
        companyData: ICompany;
        clientProcessData: IClientProcessViewModel;
        getClientHubDomainURL: (id: string, callback: (clientHubDomainURL: string) => void) => void;
		checkIfUserExistsInOneHub: (clientId: string, callback: (isUserExists: boolean) => void) => void;
		getOnehubRedirectURL: (clientId: string, callback: (redirectURL: string) => void) => void;
    }
    & typeof AccountStore.actionCreators
    & typeof HelperStore.actionCreators
    & typeof EsignStore.actionCreators
    & typeof TaxDocumentStore.actionCreators
    & RouteComponentProps<{}>;

const localStore = container.get<ILocalStore>(TYPES.ILocalStore);

// const SessionIdleMinutes = window.Variables.sessionTimeout / 60;
const WarningVisibleMinutes = 1;

export interface IProfileData {
	companyId: number;
	companyName: string;
	clientGuid: string;
	clientName: string;
	emailAddress: string;
}

interface IDefaultLayoutState {
    showTimeout: boolean;
    idleSessionTimeoutInSeconds: number;
    profileData: IProfileData | undefined;
	isPendoInjected: boolean;
};

export class DefaultLayout extends React.Component<DefaultLayoutProps, IDefaultLayoutState> {

    private idleTimer: any;
    private sessionRenew: any;

    constructor(props: DefaultLayoutProps, states: any) {
        super(props, states);
        this.idleTimer = React.createRef();
        this.state = {
            showTimeout: false,
            idleSessionTimeoutInSeconds: 0,
            profileData: undefined,
            isPendoInjected: false,
        };
    }

    componentDidMount() {
        let param: any = this.props.match.params;
        this.props.requestHeaderInfo(param.clientId);
        this.props.requestAllTaxingAuthorities(param.clientId);
        this.props.requestTaxDocumentclientType(param.clientId);
        this.props.requestMyDownload(param.clientId);
        this.props.getSessionTimeOutSeconds(param.clientId, this.setSessionRenew);
        getClientBasicInfo(param.clientId, this.updateProfileData);
    }

    componentDidUpdate(prevProps: Readonly<DefaultLayoutProps>, prevState: Readonly<IDefaultLayoutState>): void {
		if (!this.state.isPendoInjected && this.state.profileData) {
			const { companyId, companyName, clientGuid, clientName, emailAddress } = this.state.profileData;
			injectPendoScript(
				companyId,
				companyName,
				clientGuid,
				clientName,
				emailAddress,
				this.state.isPendoInjected,
				this.setIsPendoInjected
			);
		}
	}

    setIsPendoInjected = (isPendoInjected: boolean) => {
		this.setState({ isPendoInjected });
	}

	updateProfileData = (data: IProfileData) => {
		this.setState({ profileData: data });
	}

    setSessionRenew = (sessionTimeoutValue: number) => {
        this.setState({ idleSessionTimeoutInSeconds: sessionTimeoutValue })
        this.sessionRenew = setInterval(() => {
            this.renewSession();
        }, 1000 * this.state.idleSessionTimeoutInSeconds);
    }

    componentWillUnmount() {
        clearInterval(this.sessionRenew);
    }

    public render() {
        return (
            //<LoadingOverlay
            //    active={this.props.loading}
            //    spinner={<MetroSpinner size={60} color="#3bbaea" loading={this.props.loading} />}>
            <div>
                {this.state.idleSessionTimeoutInSeconds > 0 && <IdleTimer
                    key='idleTimer'
                    ref={this.idleTimer}
                    onActive={this.onActive}
                    element={document}
                    onAction={this.onAction}
                    onIdle={this.onIdle}
                    debounce={10000}
                    timeout={1000 * 60 * ((this.state.idleSessionTimeoutInSeconds / 60) - WarningVisibleMinutes)} />}
                <Header
                    headerInfo={this.props.headerInfo}
                    match={this.props.match}
                    history={this.props.history}
                    taxdocument={this.props.taxReturn}
                    refreshTaxDocument={this.props.requestTaxDocument}
                    updateEmailAddress={this.props.updateSpouseMail}
                    updateMobileNumber={this.props.updateMobileNumber}
                    signerData={this.props.signerData}
                    updateTaxDocument={this.props.updateTaxDocument}
                    downlistList={this.props.downloadList.myDownloadList}
                    deleteMyDownloads={this.props.deleteMyDownloads}
                    clearAllMyDownloads={this.props.clearAllMyDownloads}
                    validateTimeBasedAccessCode={this.props.validateTimeBasedAccessCode}
                    getIceServers={this.props.getIceServers}
                    iceServers={this.props.iceServers}
                    companyData={this.props.companyData}
                    clientProcessData={this.props.clientProcessData}
                    getClientHubDomainURL={this.props.getClientHubDomainURL}
					checkIfUserExistsInOneHub={this.props.checkIfUserExistsInOneHub}
					getOnehubRedirectURL={this.props.getOnehubRedirectURL}
                    logout={this.props.logout}
                />
                <div id="content-wrapper" className="content-wrapper" style={this.props.layoutStyle}>
                    <Notification />
                    {this.props.children}
                </div>
                <OverlayLoader />
                <Toaster />

                {this.state.idleSessionTimeoutInSeconds > 0 && <SessionTimeout
                    history={this.props.history}
                    match={this.props.match}
                    showModal={this.state.showTimeout}
                    onStayAlive={this.onStayAlive}
                    countDownMinutes={WarningVisibleMinutes}
                    loggedInUserInfo={this.props.headerInfo.loggedInUserInfo}
                    logout={this.props.logout}
                />}
                {/*{*/}
                {/*    <SignalRWebSocket*/}
                {/*        match={this.props.match}*/}
                {/*        requestMyDownload={this.props.requestMyDownload}*/}
                {/*    />*/}
                {/*}*/}

            </div>

            //</LoadingOverlay >
        );
    }

    private onIdle = (e: any) => {
        this.idleTimer.current.reset();
        this.setState({ showTimeout: true })
        logger.trackEvent(
            logger.buildEvent(
                `${LogEventConstants.Common.Open} Session Timeout Modal`,
                { count: 1, page: "Session Timeout Modal" }
            )
        );
    }

    private onActive = (e: any) => {
        this.setState({ showTimeout: false })
    }

    private onAction = (e: any) => {
        let param: any = this.props.match.params;
        this.props.refreshCookies(param.clientId);
    }

    private onStayAlive = (e: React.SyntheticEvent<EventTarget>) => {
        e.preventDefault();
        let param: any = this.props.match.params;
        this.props.refreshToken(param.clientId, this.onCountDownComplete);
        this.setState({ showTimeout: false });
    }

    private renewSession = () => {
        if (!this.state.showTimeout) {
            let param: any = this.props.match.params;
            this.props.refreshToken(param.clientId, this.onCountDownComplete);
        }
    }
    private onCountDownComplete = () => {
        if (this.props.headerInfo.loggedInUserInfo.role.toString() === Role[Role.CPA].toString()) {
            window.close();
        } else {

            let param: any = this.props.match.params;
            this.props.logout(param.controllerId ? param.controllerId : param.clientId, this.onLogOutCompletion);
        }
    }
    onLogOutCompletion = (id: string) => {
        let param: any = this.props.match.params;
        const url = param.controllerId ? PathConstants.ControllerLogin + id : PathConstants.ClientLogin + id;
        this.props.history.push(url);
    }
}
